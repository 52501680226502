// PrivateRoute.js
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from './contexts/AuthContexts';

const PrivateRoute = () => {
    const { currentUser, activePlan } = useAuth();
    const location = useLocation();

    if (currentUser === null) {
        // Redirect to login if not authenticated
        return <Navigate to="/login" />;
    }

    if (!activePlan && location.pathname !== '/select-plan') {
        // Redirect to plan selection if no active plan, unless already on the plan selection page
        return <Navigate to="/select-plan" />;
    }

    // User is authenticated and has an active plan
    return <Outlet />;
};

export default PrivateRoute;
