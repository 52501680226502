import React from 'react';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import '../styles/FilterModal.css';

const FilterModal = ({ show, handleClose, allTags, selectedTags, onTagChange, onApplyFilters, onSortChange }) => {
  const sortOptions = [
    { value: 'recent', label: 'Más reciente' },
    { value: 'oldest', label: 'Más antiguo' },
    { value: 'price_asc', label: 'Menor precio' },
    { value: 'price_desc', label: 'Mayor precio' }
  ];

  return (
    <Modal 
      show={show} 
      onHide={handleClose}
      centered
      className="filter-modal"
      fullscreen={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Filtros</Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
        <div className="filter-section">
          <h5>Ordenar</h5>
          <Select
            className="sort-select"
            classNamePrefix="select"
            placeholder="Más reciente"
            options={sortOptions}
            onChange={onSortChange}
          />
        </div>

        <div className="filter-section">
          <h5>Etiquetas</h5>
          <Select
            isMulti
            name="tags"
            options={allTags}
            className="tag-select"
            classNamePrefix="select"
            placeholder="Selecciona una opción..."
            onChange={onTagChange}
            value={selectedTags}
          />
          <small className="helper-text">Da Enter para agregar</small>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button className="filter-apply-button" onClick={onApplyFilters}>
          Filtrar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterModal; 