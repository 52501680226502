import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Toast } from 'react-bootstrap';
import { ExclamationCircle } from 'react-bootstrap-icons';
import Select from 'react-select';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import './styles/AddMemberModal.css';

const AddContactModal = ({ show, handleClose, saveMember, deleteMember, member, openAddPaymentModal }) => {
    const [contactName, setContactName] = useState('');
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [stripeCustomerId, setStripeCustomerId] = useState('');
    const [clientSubscribed, setClientSubscribed] = useState(false);
    const [activeSuscriptions, setActiveSuscriptions] = useState(null);
    const [activeSubscriptions, setActiveSubscriptions] = useState([]);
    const [localActiveSuscriptions, setLocalActiveSuscriptions] = useState([]);
    const [isFormModified, setIsFormModified] = useState(false);
    const [showWarningToast, setShowWarningToast] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        if (member) {
            setContactName(member.contact_name || '');
            setNumber(member.number || '');
            setEmail(member.email || '');
            setStripeCustomerId(member.stripeCustomerId || '');
            setClientSubscribed(member.clientSubscribed || false);
            setActiveSuscriptions(member.activeSuscriptions || null);
            setActiveSubscriptions(member.activeSubscriptions || []);
            setLocalActiveSuscriptions(member.activeSuscriptions || []);
            setIsFormModified(false);
        } else {
            setContactName('');
            setNumber('');
            setEmail('');
            setStripeCustomerId('');
            setClientSubscribed(false);
            setActiveSuscriptions(null);
            setActiveSubscriptions([]);
            setLocalActiveSuscriptions([]);
            setIsFormModified(false);
        }
        setFormErrors({});
    }, [member]);

    const handleInputChange = (setter) => (e) => {
        setter(e.target.value);
        setIsFormModified(true);
        console.log('Form modified:', true); // Add this line
    };

    const handleNumberChange = (e) => {
        const value = e.target.value;
        const cleaned = value.replace(/\D/g, '');
        if (cleaned.length <= 10) {
            setNumber(cleaned);
            setIsFormModified(true);
        }
    };

    const validateForm = () => {
        const errors = {};
        if (!contactName.trim()) {
            errors.contactName = 'El nombre es requerido';
        }
        if (!number || number.length !== 10) {
            errors.number = 'Se requiere un número de celular válido de 10 dígitos';
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            saveMember({
                contact_name: contactName,
                number: number,
                email: email || null,
                status: 'Nuevo',
                clientSubscribed: localActiveSuscriptions.length > 0,
                activeSuscriptions: localActiveSuscriptions,
            });
            handleClose();
        }
    };

    const handleSaveAndCreatePayment = async () => {
        if (validateForm()) {
            try {
                const savedMember = await saveMember({
                    contact_name: contactName,
                    number: number,
                    email: email || null,
                    status: 'Nuevo',
                    clientSubscribed: member ? member.clientSubscribed : false,
                    activeSuscriptions: member ? member.activeSuscriptions : null,
                });
                console.log("Saved member data:", savedMember);
                handleClose();
                if (savedMember && typeof openAddPaymentModal === 'function') {
                    openAddPaymentModal({
                        memberId: savedMember.id,
                        memberData: savedMember
                    });
                } else {
                    console.warn('openAddPaymentModal is not a function or not provided');
                }
            } catch (error) {
                console.error('Error saving member:', error);
            }
        }
    };

    const handleDelete = () => {
        if (member && member.id) {
            deleteMember(member.id);
            handleClose();
        }
    };

    const handleRemoveSubscription = async (classId) => {
        if (!activeSuscriptions) return;

        const updatedSuscriptions = activeSuscriptions.filter(sub => sub.classId !== classId);
        setActiveSuscriptions(updatedSuscriptions);
        
        const updatedMember = {
            ...member,
            activeSuscriptions: updatedSuscriptions,
            clientSubscribed: updatedSuscriptions.length > 0
        };

        try {
            await saveMember(updatedMember);
        } catch (error) {
            console.error('Error updating subscriptions:', error);
        }
    };

    const handleUnsubscribe = (classId) => {
        setLocalActiveSuscriptions(prevSuscriptions => 
            prevSuscriptions.filter(sub => sub.classId !== classId)
        );
        setIsFormModified(true);
    };

    const handleCloseAttempt = () => {
        if (isFormModified && !showWarningToast) {
            setShowWarningToast(true);
            setIsClosing(true);
        } else {
            handleFinalClose();
        }
    };

    const handleFinalClose = () => {
        setShowWarningToast(false);
        setIsClosing(false);
        setIsFormModified(false);
        handleClose();
    };

    const handleCancelClose = () => {
        setShowWarningToast(false);
        setIsClosing(false);
    };

    useEffect(() => {
        if (showWarningToast) {
            const timer = setTimeout(() => {
                if (isClosing) {
                    handleFinalClose();
                }
            }, 5000); // 5 seconds timeout

            return () => clearTimeout(timer);
        }
    }, [showWarningToast, isClosing]);

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                document.querySelector('.modal-dialog-slideout').classList.add('show');
            }, 50);
        } else {
            document.querySelector('.modal-dialog-slideout')?.classList.remove('show');
        }
    }, [show]);

    useEffect(() => {
        const adjustModalBodyPadding = () => {
            const modalBody = document.querySelector('.modal-body');
            if (modalBody) {
                const headerHeight = document.querySelector('.modal-header').offsetHeight;
                modalBody.style.paddingTop = `${headerHeight}px`;
            }
        };

        if (show) {
            adjustModalBodyPadding();
            window.addEventListener('resize', adjustModalBodyPadding);
        }

        return () => {
            window.removeEventListener('resize', adjustModalBodyPadding);
        };
    }, [show]);

    return (
        <>
            <Modal 
                show={show} 
                onHide={handleCloseAttempt}
                className="custom-side-modal"
                dialogClassName="modal-dialog-slideout"
                aria-labelledby="example-custom-modal-styling-title"
                fullscreen="lg-down"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{member ? 'Editar cliente' : 'Agregar cliente'}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="dynamic-padding">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>Nombre completo*</Form.Label>
                            <Form.Control
                                type="text"
                                value={contactName}
                                onChange={handleInputChange(setContactName)}
                                isInvalid={!!formErrors.contactName}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formErrors.contactName}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Celular (WhatsApp)*</Form.Label>
                            <Form.Control
                                type="tel"
                                value={number}
                                onChange={handleNumberChange}
                                maxLength="10"
                                isInvalid={!!formErrors.number}
                                placeholder="Ingresa un número de 10 dígitos"
                            />
                            <Form.Control.Feedback type="invalid">
                                {formErrors.number}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Email (opcional)</Form.Label>
                            <Form.Control
                                type="email"
                                value={email}
                                onChange={handleInputChange(setEmail)}
                                placeholder="Ingresa dirección de email"
                            />
                        </Form.Group>

                        {member && (
                            <Form.Group>
                                <Form.Label>Suscripciones Activas</Form.Label>
                                <div className="d-flex flex-wrap gap-2">
                                    {localActiveSuscriptions.length > 0 ? (
                                        localActiveSuscriptions.map(subscription => (
                                            <div key={subscription.classId} className="bg-light rounded-pill px-3 py-2 d-flex align-items-center">
                                                <span className="me-2">
                                                    {subscription.className} • ${subscription.cost} ({subscription.cadence})
                                                </span>
                                                <button 
                                                    type="button"
                                                    className="btn-close btn-close-sm"
                                                    onClick={() => handleUnsubscribe(subscription.classId)}
                                                    aria-label="Remove"
                                                ></button>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-muted">No hay suscripciones activas</p>
                                    )}
                                </div>
                            </Form.Group>
                        )}
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <div className="d-flex flex-column align-items-stretch w-100">
                        <Button 
                            className="modal-button btn btn-primary w-100" 
                            variant="primary" 
                            onClick={handleSubmit}
                            disabled={member && !isFormModified}
                        >
                            {member ? 'Guardar cambios' : 'Agregar cliente'}
                        </Button>
                        {!member && (
                            <Button 
                                className="modal-scondary-cta btn btn-secondary mb-2" 
                                variant="secondary" 
                                onClick={handleSaveAndCreatePayment}
                            >
                                Guardar y crear pago
                            </Button>
                        )}
                        {member && (
                            <Button className="custome-danger-button btn btn-danger" variant="danger" onClick={handleDelete}>
                                Eliminar
                            </Button>
                        )}
                    </div>
                </Modal.Footer>
            </Modal>

            {showWarningToast && (
                <div className="custom-toast-container">
                    <Toast
                        onClose={handleCancelClose}
                        show={showWarningToast}
                        delay={5000}
                        autohide
                        className="custom-toast warning"
                    >
                        <Toast.Body>
                            <div className="toast-content">
                                <strong>Hay cambios sin guardar.</strong>
                            </div>
                            <Button 
                                variant="link" 
                                className="toast-close-btn" 
                                onClick={handleFinalClose}
                            >
                                ×
                            </Button>
                        </Toast.Body>
                    </Toast>
                </div>
            )}
        </>
    );
};

export default AddContactModal;
