import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Button, Container } from 'react-bootstrap';
import { ArrowLeft, Camera } from 'react-bootstrap-icons';
import { doc, getDoc, deleteDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../config/firebase-config';
import AddEventModal from '../AddEventModal';
import '../styles/EventDetails.css';
import { RRule } from 'rrule';
import DeleteRecurringEventModal from './DeleteRecurringEventModal';


const EventDetails = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [classes, setClasses] = useState([]);
  const [activeMembers, setActiveMembers] = useState([]);
  const auth = getAuth();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error('No user logged in!');
          navigate('/login');
          return;
        }

        console.log('Fetching event with ID:', eventId);

        const eventDoc = await getDoc(doc(db, 'events', eventId));
        
        console.log('Event doc exists:', eventDoc.exists());
        
        if (!eventDoc.exists()) {
          console.log('Event not found in database');
          setError('Event not found');
          return;
        }

        const eventData = eventDoc.data();
        console.log('Event data with recurrence:', eventData);
        
        // Verify the event belongs to the current user
        if (eventData.createdBy !== user.uid) {
          console.log('User ID mismatch:', { 
            eventCreator: eventData.createdBy, 
            currentUser: user.uid 
          });
          setError('Unauthorized access');
          return;
        }

        // Handle both string dates and Firestore timestamps
        const parseEventDate = (dateValue) => {
          if (dateValue && typeof dateValue.toDate === 'function') {
            return dateValue.toDate();
          }
          return new Date(dateValue);
        };

        // Parse recurrence from rrule
        const recurrence = eventData.rrule ? getRecurrenceFromRRule(eventData.rrule) : null;

        setEvent({
          id: eventDoc.id,
          ...eventData,
          start: parseEventDate(eventData.start),
          end: parseEventDate(eventData.end),
          recurrence: recurrence // Set recurrence based on rrule parsing
        });
      } catch (error) {
        console.error('Detailed error fetching event:', error);
        setError('Error loading event details');
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [eventId, navigate, auth]);

  useEffect(() => {
    const fetchClassesAndMembers = async () => {
      try {
        // Fetch classes
        const classesSnapshot = await getDocs(query(
          collection(db, 'classes'),
          where('createdBy', '==', auth.currentUser.uid)
        ));
        setClasses(classesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })));

        // Fetch members
        const membersSnapshot = await getDocs(query(
          collection(db, 'members'),
          where('createdBy', '==', auth.currentUser.uid)
        ));
        setActiveMembers(membersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })));
      } catch (error) {
        console.error('Error fetching classes and members:', error);
      }
    };

    if (auth.currentUser) {
      fetchClassesAndMembers();
    }
  }, [auth.currentUser]);

  const handleDelete = () => {
    if (event.rrule) {
      setShowDeleteModal(true);
    } else {
      handleSingleEventDelete();
    }
  };

  const handleSingleEventDelete = async () => {
    try {
      await deleteDoc(doc(db, 'events', eventId));
      navigate(-1);
    } catch (error) {
      console.error('Error deleting event:', error);
      setError('Error deleting event');
    }
  };

  const handleRecurringDelete = async (deleteOption) => {
    try {
      const eventRef = doc(db, 'events', eventId);
      const eventDoc = await getDoc(eventRef);
      const eventData = eventDoc.data();

      if (eventData.rrule) {
        // Split DTSTART and RRULE components
        const [dtstart, rruleString] = eventData.rrule.split('\nRRULE:');
        const rule = RRule.fromString(rruleString);
        
        switch (deleteOption) {
          case 'this':
            const exdates = eventData.exdates || [];
            await updateDoc(eventRef, {
              exdates: [...exdates, event.start.toISOString()]
            });
            break;
            
          case 'future':
            const updatedOptions = {
              ...rule.options,
              until: event.start
            };
            const updatedRule = new RRule(updatedOptions);
            // Combine with original DTSTART
            const newRrule = `${dtstart}\nRRULE:${updatedRule.toString()}`;
            await updateDoc(eventRef, {
              rrule: newRrule
            });
            break;
            
          case 'all':
            await deleteDoc(eventRef);
            break;
        }
      } else {
        await deleteDoc(eventRef);
      }
      
      navigate(-1);
    } catch (error) {
      console.error('Error handling recurring event deletion:', error);
      setError('Error deleting recurring event');
    }
  };

  const handleEditSave = async (updatedEventData) => {
    try {
      await updateDoc(doc(db, 'events', eventId), updatedEventData);
      // Refresh event data
      const newEventDoc = await getDoc(doc(db, 'events', eventId));
      setEvent({
        id: newEventDoc.id,
        ...newEventDoc.data(),
        start: newEventDoc.data().start.toDate(),
        end: newEventDoc.data().end.toDate()
      });
    } catch (error) {
      console.error('Error updating event:', error);
      setError('Error updating event');
    }
    setShowEditModal(false);
  };

  const getRecurrenceText = (recurrence) => {
    console.log('Recurrence value:', recurrence);
    switch (recurrence) {
      case 'daily':
        return 'Se repite diariamente';
      case 'weekly':
        return 'Se repite semanalmente';
      case 'monthly':
        return 'Se repite mensualmente';
      default:
        return null;
    }
  };

  if (loading) return <div>Cargando...</div>;
  if (error) return <div>{error}</div>;
  if (!event) return <div>Evento no encontrado</div>;

  const formatEventDate = (date) => {
    return format(date, "EEEE, dd 'de' MMMM 'de' yyyy", { locale: es });
  };

  const formatEventTime = (start, end) => {
    return `${format(start, 'H:mm')} - ${format(end, 'H:mm')}`;
  };

  return (
    <div className="event-details-page">
      <Container className="event-details-container">
        <div className="event-content">
          <div className="event-details-header">
            <Button variant="link" onClick={() => navigate(-1)} className="back-button">
              Volver
            </Button>
            <h1 className="event-title">{event.title || event.class_name}</h1>
            <div className="event-datetime">
              <div className="event-date">{formatEventDate(event.start)}</div>
              <div className="event-time">{formatEventTime(event.start, event.end)}</div>
              {event.recurrence && (
                <div className="event-recurrence">
                  <span className="recurrence-icon">↻</span>
                  {getRecurrenceText(event.recurrence)}
                </div>
              )}
            </div>
            
            {event.videoCall && (
              <div className="video-call-buttons">
                <Button variant="outline-primary" className="video-button">
                  <Camera /> Iniciar videollamada
                </Button>
                <Button 
                  variant="outline-secondary" 
                  onClick={() => navigator.clipboard.writeText(event.videoCall)}
                >
                  Copiar liga
                </Button>
              </div>
            )}
          </div>

          <div className="event-sections">
            {/* Only show patient section if there's a member name */}
            {event.memberName && (
              <section className="event-section">
                <h2>PACIENTES</h2>
                <div className="section-content">
                  <span>{event.memberName}</span>
                  <span className="status-tag">
                    {event.confirmationStatus === 'confirmed' ? 'Confirmado' : 'Pendiente'}
                  </span>
                </div>
              </section>
            )}

            {/* Only show service section if there's a class name */}
            {event.class_name && (
              <section className="event-section">
                <h2>SERVICIOS</h2>
                <div className="section-content">
                  <span>{event.class_name}</span>
                </div>
              </section>
            )}
          </div>
        </div>

        <div className="event-actions">
          <Button variant="outline-danger" onClick={handleDelete} className="delete-button">
            Eliminar
          </Button>
          <Button variant="dark" onClick={() => setShowEditModal(true)} className="edit-button">
            Editar
          </Button>
        </div>

        <AddEventModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          handleSave={handleEditSave}
          selectedDate={event?.start}
          classes={classes}
          activeMembers={activeMembers}
          initialData={event}
        />

        <DeleteRecurringEventModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          eventTitle={event?.title || event?.class_name}
          onDelete={handleRecurringDelete}
        />
      </Container>
    </div>
  );
};

// Add this helper function to parse RRULE
const getRecurrenceFromRRule = (rrule) => {
  if (!rrule) return null;
  
  // Extract the FREQ parameter from the RRULE string
  const freqMatch = rrule.match(/FREQ=([^;]+)/);
  if (!freqMatch) return null;
  
  const freq = freqMatch[1].toLowerCase();
  switch (freq) {
    case 'daily':
      return 'daily';
    case 'weekly':
      return 'weekly';
    case 'monthly':
      return 'monthly';
    default:
      return null;
  }
};

export default EventDetails;