import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../config/firebase-config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import '../styles/PaymentDetails.css';

const PaymentDetails = () => {
  const [payment, setPayment] = useState(null);
  const [loading, setLoading] = useState(true);
  const { paymentId } = useParams();
  const navigate = useNavigate();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editedStatus, setEditedStatus] = useState('');
  const [editedInvoiceStatus, setEditedInvoiceStatus] = useState('');

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const paymentDoc = await getDoc(doc(db, 'payments', paymentId));
        if (paymentDoc.exists()) {
          setPayment({ id: paymentDoc.id, ...paymentDoc.data() });
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching payment details:', error);
        setLoading(false);
      }
    };

    fetchPaymentDetails();
  }, [paymentId]);

  const handleSaveEdit = async () => {
    try {
      const paymentRef = doc(db, 'payments', paymentId);
      await updateDoc(paymentRef, {
        status: editedStatus,
        invoiceStatus: editedInvoiceStatus,
      });
      
      setPayment(prev => ({
        ...prev,
        status: editedStatus,
        invoiceStatus: editedInvoiceStatus
      }));
      
      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Error updating payment:', error);
    }
  };

  const handleEditClick = () => {
    setEditedStatus(payment.status || 'Pagado');
    setEditedInvoiceStatus(payment.invoiceStatus || 'Por facturar');
    setIsEditModalOpen(true);
  };

  if (loading) {
    return <div className="loading">Cargando...</div>;
  }

  if (!payment) {
    return <div className="error">Pago no encontrado</div>;
  }

  const formatCurrency = (amount) => {
    return `$${amount.toLocaleString('es-MX')} mxn`;
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = timestamp.toDate();
    return date.toLocaleDateString('es-MX', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <div className="payment-details-container">
      <div className="payment-details-header">
        <button className="back-button" onClick={() => navigate(-1)}>
        Volver
        </button>
        <h1 className="payment-amount">{formatCurrency(payment.totalAmount)}</h1>
      </div>

      <div className="payment-details-content">
        <div className="detail-section">
          <h2>CLIENTE</h2>
          <p>{payment.clientName}</p>
        </div>

        <div className="detail-section">
          <h2>CONCEPTOS</h2>
          {payment.itemsPaid?.map((item, index) => (
            <p key={index}>{item.name}</p>
          ))}
        </div>

        <div className="detail-section">
          <h2>MÉTODO DE PAGO</h2>
          <p>{payment.paymentMethod}</p>
        </div>

        <div className="detail-section">
          <h2>ESTADO</h2>
          <span className={`status-tag ${payment.status?.toLowerCase()}`}>
            {payment.status}
          </span>
        </div>

        <div className="detail-section">
          <h2>FECHA</h2>
          <p>{formatDate(payment.date)}</p>
        </div>

        <div className="detail-section">
          <h2>FACTURA</h2>
          <button className="invoice-button">
            Por facturar
          </button>
        </div>
      </div>

      <div className="action-buttons">
        <button className="delete-button">Eliminar</button>
        <button className="edit-button" onClick={handleEditClick}>
          Editar
        </button>
      </div>

      {isEditModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Editar pago</h2>
              <button 
                className="close-button"
                onClick={() => setIsEditModalOpen(false)}
              >
                ×
              </button>
            </div>
            
            <div className="modal-body">
              <div className="form-group">
                <label>Estado del pago</label>
                <select 
                  value={editedStatus}
                  onChange={(e) => setEditedStatus(e.target.value)}
                >
                  <option value="Pagado">Pagado</option>
                  <option value="Pendiente">Pendiente</option>
                  <option value="Cancelado">Cancelado</option>
                </select>
              </div>

              <div className="form-group">
                <label>Estado de la factura</label>
                <select
                  value={editedInvoiceStatus}
                  onChange={(e) => setEditedInvoiceStatus(e.target.value)}
                >
                  <option value="Por facturar">Por facturar</option>
                  <option value="Facturado">Facturado</option>
                </select>
              </div>
            </div>

            <div className="modal-footer">
              <button 
                className="save-button"
                onClick={handleSaveEdit}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentDetails;